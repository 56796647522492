import { RouteInfo } from './sidebar.metadata';
import * as role from "../../utils/role/role";

export const ROUTES: RouteInfo[] = [
  // {
  //   path: '',
  //   title: 'Personal',
  //   icon: 'mdi mdi-dots-horizontal',
  //   class: 'nav-small-cap',
  //   extralink: true,
  //   submenu: []
  // },
  {
    path: '',
    title: 'Dashboards',
    icon: 'mdi mdi-view-dashboard',
    class: 'has-arrow',
    extralink: false,
    roles: [
      role.SUPERADMIN.GetCodeStr(),
      role.ADMIN.GetCodeStr(),
    ],
    submenu: [
      {
        path: '/dashboard/dashboard',
        title: 'Dashboard',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      }, 
      {
        path: '/dashboard/sale',
        title: 'Dashboard Transaction',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      }, 
      // {
      //   path: '/dashboard/settlement',
      //   title: 'Settlement',
      //   icon: 'mdi mdi-adjust',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // }, 
      {
        path: '/dashboard/transactions',
        title: 'Area',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      }, 
      {
        path: '/dashboard/operator',
        title: 'Jukir',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      }, 
      {
        path: '/dashboard/terminal',
        title: 'Terminal',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
      {
        path: '/dashboard/payment',
        title: 'Payment',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
      // {
      //   path: '/dashboard/duration',
      //   title: 'Duration',
      //   icon: 'mdi mdi-adjust',
      //   class: '',
      //   extralink: false,
      //   roles: [],
      //   submenu: []
      // },
      {
        path: '/dashboard/details',
        title: 'Details',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
      {
        path: '/dashboard/details-ext',
        title: 'Details-Extended',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
      {
        path: '/dashboard/details-midtrans',
        title: 'Details-Midtrans',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: 'has-arrow',
    extralink: false,
    roles: [
      role.VIEWER.GetCodeStr(),
    ],
    submenu: [
      {
        path: '/dashboard/dashboard',
        title: 'Dashboard',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      }, 
      {
        path: '/dashboard/sale',
        title: 'Dashboard Transaction',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Manage BSS',
    icon: 'mdi mdi-city',
    class: 'has-arrow',
    extralink: false,
    roles: [
      // role.SUPERADMIN.GetCodeStr(),
      role.ROOT.GetCodeStr(),
    ],
    submenu: [
      {
        path: '/bss/bss',
        title: 'BSS',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
      // {
      //   path: '/bss/maptype',
      //   title: 'MapType',
      //   icon: 'mdi mdi-adjust',
      //   class: '',
      //   extralink: false,
      //   roles: [],
      //   submenu: []
      // },
    ]
  },
  {
    path: '',
    title: 'Statistic BSS',
    icon: 'mdi mdi-view-dashboard',
    class: 'has-arrow',
    extralink: false,
    roles: [
      // role.SUPERADMIN.GetCodeStr(),
      role.ROOT.GetCodeStr(),
    ],
    submenu: [
      {
        path: '/dashboard/statistic',
        title: 'BSS Statistic',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
      {
        path: '/dashboard/userstat',
        title: 'BSS User Statistic',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },      
    ]
  },  
  {
    path: '/bss/bssupdate',
    title: 'Setting BSS',
    icon: 'mdi mdi-city',
    class: '',
    extralink: false,
    roles: [
      role.SUPERADMIN.GetCodeStr(),
    ],
    submenu: [
    ]
  },
  {
    path: '',
    title: 'Manage Space',
    icon: 'mdi mdi-map-marker',
    class: 'has-arrow',
    extralink: false,
    roles: [
      role.SUPERADMIN.GetCodeStr(),
      role.OPERATOR.GetCodeStr(),
    ],
    submenu: [
      {
        path: '/spc/zona',
        title: 'Zona',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
      {
        path: '/spc/ruas',
        title: 'Ruas',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
      {
        path: '/spc/space',
        title: 'Space',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
      {
        path: '/spc/policy',
        title: 'Policy',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Manage User',
    icon: 'mdi mdi-account',
    class: 'has-arrow',
    extralink: false,
    roles: [
      role.SUPERADMIN.GetCodeStr(),
      role.OPERATOR.GetCodeStr(),
      role.EDCADMIN.GetCodeStr(),
      role.JUKIR.GetCodeStr(),
    ],
    submenu: [
      {
        path: '/usr/user',
        title: 'User',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
      {
        path: '/usr/jukir',
        title: 'Jukir',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
      {
        path: '/usr/edc',
        title: 'EDC',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Manage Users',
    icon: 'mdi mdi-account',
    class: 'has-arrow',
    extralink: false,
    roles: [
      role.ROOT.GetCodeStr(),
    ],
    submenu: [
      {
        path: '/usr/user',
        title: 'User',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },
    ]
  },
  // {
  //   path: '',
  //   title: 'Manage Track',
  //   icon: 'mdi mdi-blur',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/track/track',
  //       title: 'Track',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/track/trackviolation',
  //       title: 'Track Violation',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //   ]
  // },
  {
    path: '',
    title: 'Manage Subscription',
    icon: 'mdi mdi-credit-card-multiple',
    class: 'has-arrow',
    extralink: false,
    roles: [
      role.SUPERADMIN.GetCodeStr(),
      role.ADMIN.GetCodeStr(),
    ],
    submenu: [
      {
        path: '/subscription/listprice',
        title: 'Price',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      }, 
      {
        path: '/subscription/liststicker',
        title: 'Sticker',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      },            
      {
        path: '/subscription/listsubscriptions',
        title: 'Subscription',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      }, 
      {
        path: '/subscription/listpurchases',
        title: 'Purchases',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        roles: [],
        submenu: []
      }, 
    ]
  },
  // {
  //   path: '',
  //   title: 'Manage Report',
  //   icon: 'mdi mdi-file-outline',
  //   class: 'has-arrow',
  //   extralink: false,
  //   roles: [
  //     role.SUPERADMIN.GetCodeStr(), 
  //     role.ADMIN.GetCodeStr(),
  //   ],
  //   submenu: [
  //     {
  //       path: '/report/report',
  //       title: 'Report',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       roles: [],
  //       submenu: []
  //     },
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Manage Biller',
  //   icon: 'mdi mdi-file-document-box',
  //   class: 'has-arrow',
  //   extralink: false,
  //   roles: [
  //     role.SUPERADMIN.GetCodeStr(),
  //     role.ADMIN.GetCodeStr(),
  //   ],
  //   submenu: [
  //     {
  //       path: '/biller/listproduct',
  //       title: 'Product',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       roles: [],
  //       submenu: []
  //     }, 
  //     {
  //       path: '/biller/listbiller',
  //       title: 'Biller',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       roles: [],
  //       submenu: []
  //     },            
  //   ]
  // },  
];
