import { AgmCoreModule } from '@agm/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { SpinnerComponent } from './shared/spinner.component';

import { SearchZonaComponent } from '../app/shared/search-zona/search-zona.component';
import { SearchRuasComponent } from '../app/shared/search-ruas/search-ruas.component';
import { SearchSpaceComponent } from '../app/shared/search-space/search-space.component';
import { SearchBssComponent } from '../app/shared/search-bss/search-bss.component';
import { SearchUserComponent } from '../app/shared/search-user/search-user.component';
import { SearchMaptypeComponent } from '../app/shared/search-maptype/search-maptype.component';
import { SearchJukirComponent } from '../app/shared/search-jukir/search-jukir.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  entryComponents: [
    SearchSpaceComponent,
    SearchRuasComponent,
    SearchZonaComponent,
    SearchMaptypeComponent,
    SearchUserComponent,
    SearchBssComponent,
    SearchJukirComponent,
  ],
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    SearchSpaceComponent,
    SearchUserComponent,
    SearchRuasComponent,
    SearchZonaComponent,
    SearchMaptypeComponent,
    SearchBssComponent,
    SearchJukirComponent,
  ],
  imports: [
    NgxDatatableModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot(Approutes),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    PerfectScrollbarModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyAeTRN2pSmcYbuSorZU4Qcc9VW-6xX5F4o' })
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
