import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as apis from "../../utils/http/apis/list.apis"
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Observable, of } from 'rxjs';
import { map,catchError,filter} from 'rxjs/operators'
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Page} from '../../shared-components/model/page';

@Component({
  selector: 'app-search-maptype',
  templateUrl: './search-maptype.component.html',
  styleUrls: ['./search-maptype.component.css'],
})
export class SearchMaptypeComponent implements OnInit {
  @ViewChild('myTable') table: any;

  public static title = "yes"
  public static content = "oke"
  rows = [];
  page = new Page();
  loadingIndicator = false;
  expanded: any = {};
  ColumnMode = ColumnMode;
  headers:HttpHeaders;

  constructor(public activeModal: NgbActiveModal, private http: HttpClient) { 
    let usertoken=localStorage.getItem('usertoken');    
    this.headers=new HttpHeaders(
        {
            'Content-Type':  'application/json',                
            'Authorization': 'bearer '+usertoken,
        }
    ); 
  }

  ngOnInit() {
    this.search('')
  }

  public static setTitleAndContent(title, content) {
    this.title = title
    this.content = content
  }

  get title() {
    return SearchMaptypeComponent.title;
  }

  get content() {
    return SearchMaptypeComponent.content;
  }

  search(name:string){
    this.loadingIndicator = true;
    let params = new HttpParams()
    .set('name', name)
    this.onGetSpace(params)

  }

  onActivate(event) {
    if(event.type == 'click') {
      // console.log(event.row.id)
      // this.activeModal.close(event.row)
    }
  }

  onSelect(row) {
    // console.log("on select ",row.id)
    this.activeModal.close(row)
  }

  toggleExpandRow(row) {
    // console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    // console.log('Detail Toggled', event);
  }

  onGetSpace(param){
    this.getService(apis.maptypeAPI, param)
                .subscribe(response=>{
                of(response)
                .subscribe( (response:any) =>{ 
                    this.rows = [];
                    this.rows = response.message.items;

                    this.page.size = response.message.perpage
                    this.page.pageNumber = response.message.page-1
                    this.page.totalPages = response.message.last_page
                    this.page.totalElements = response.message.total

                    this.loadingIndicator = false;
                    }
                )
        })
}

setPage(pageInfo){
  this.loadingIndicator = true;
  let params = new HttpParams()
  .set('page', (pageInfo.offset+1).toString())
  this.onGetSpace(params)

}


getService(url:string, param:any){
    let usertoken=localStorage.getItem('usertoken');    
    this.headers=new HttpHeaders(
        {
            'Content-Type':  'application/json',                
            'Authorization': 'bearer '+usertoken,
        }
    ); 
  
    return this.http.get(url, {headers: this.headers, params: param})      
    .pipe(
            map((response: Response) => {
              return response;
            }),
            catchError((error:any)=> 
            Observable.throw(error.json()||'Server error'))
            );
}

}