import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { map,catchError,filter, first} from 'rxjs/operators'
import { Observable, of } from 'rxjs';
import * as apis from "../../utils/http/apis/list.apis"


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  headers:HttpHeaders;
  username = '';
  email = '';
  bssname = '';
  showMenu = '';
  showSubMenu = '';
  role = '';
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private http:HttpClient,
  ) {
    let usertoken= localStorage.getItem('usertoken');    
    this.headers=new HttpHeaders(
        {
            'Content-Type':  'application/json',                
            'Authorization': 'bearer '+usertoken,
        }
    ); 

  }

  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    this.username = localStorage.getItem('username');
    if ( this.username && this.username.length > 16 ) {
      this.username = this.username.slice(0, 16)
    }
    this.email = localStorage.getItem('email');
    this.bssname =localStorage.getItem('bss_name');
    if (this.bssname && this.bssname.length > 16 ) {
      this.bssname = this.bssname.slice(0, 16)
    }
    this.role =localStorage.getItem('role'); 
  }

  logout(){
    this.newService(apis.logoutApi)
    .subscribe(response=>{
    of(response)
    .subscribe( (response:any) =>{ 
            alert("SUCCESS response "+ response.status)
            localStorage.clear()
            this.router.navigate(['/','authentication','login'])

            }
        )
    })
  }

  newService(url:string){
    return this.http.post(url, JSON.stringify({}), {headers: this.headers})      
    .pipe(
            map((response: Response) => {
              return response;
            }),
            catchError((error:any)=> 
            Observable.throw(error.json()||'Server error'))
            );
    }

}