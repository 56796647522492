// export const baseUrl = "https://bssdev.parkir.co";
// export const baseUrl = "https://bssdemo.parkir.co";
// export const baseUrl = "https://parkingdev.parkir.co";
// export const baseUrl = "http://local.parkir";
// export const baseUrl = "https://smart.parkirin.co";
// export const baseUrl = "https://demo.parkirin.co";


const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

export const baseUrl = browserWindowEnv.baseUrl

export const loginApi = baseUrl + "/api/v1/login";
export const logoutApi = baseUrl + "/api/v1/logout";

export const branchAPI = baseUrl + "/api/v1/org/search";

export const userAPI = baseUrl + "/api/v1/user/search";
export const userDetailAPI = baseUrl + "/api/v1/user/get/";
export const userNewAPI = baseUrl + "/api/v1/user/new";
export const addUserAPI = baseUrl + "/api/v1/register";
export const userUpdateAPI = baseUrl + "/api/v1/user/update";
export const userPasswordUpdateAPI = baseUrl + "/api/v1/user/password";
export const userDeleteAPI = baseUrl + "/api/v1/user/delete/";


export const jukirAPI = baseUrl + "/api/v1/jukir/search";
export const jukirDetailAPI = baseUrl + "/api/v1/jukir/get/";
export const jukirNewAPI = baseUrl + "/api/v1/jukir/new";
export const jukirDeleteAPI = baseUrl + "/api/v1/jukir/delete/";

export const webhookAPI = baseUrl + "/api/wbk/webhook/search";
export const webhookDetailAPI = baseUrl + "/api/wbk/webhook/get/";
export const webhookNewAPI = baseUrl + "/api/wbk/webhook/new";
export const webhookUpdateAPI = baseUrl + "/api/wbk/webhook/update";

export const deviceAPI = baseUrl + "/api/v1/device/search";
export const deviceDetailAPI = baseUrl + "/api/v1/device/get/";
export const deviceNewAPI = baseUrl + "/api/v1/device/new";

export const spaceAPI = baseUrl + "/api/v1/space/search";
export const spaceDetailAPI = baseUrl + "/api/v1/space/get/";
export const spaceNewAPI = baseUrl + "/api/v1/space/new";
export const spaceUpdateAPI = baseUrl + "/api/v1/space/update";
export const spaceDeleteAPI = baseUrl + "/api/v1/space/delete/";

export const policyAPI = baseUrl + "/api/v1/policy/search";
export const policyDetailAPI = baseUrl + "/api/v1/policy/get/";
export const policyNewAPI = baseUrl + "/api/v1/policy/new";
export const policyUpdateAPI = baseUrl + "/api/v1/policy/update";
export const policyDeleteAPI = baseUrl + "/api/v1/policy/delete/";
export const spacepolicyAPI = baseUrl + "/api/v1/spacepolicy/get/";


export const ruasAPI = baseUrl + "/api/v1/ruas/search";
export const ruasDetailAPI = baseUrl + "/api/v1/ruas/get/";
export const ruasNewAPI = baseUrl + "/api/v1/ruas/new";
export const ruasUpdateAPI = baseUrl + "/api/v1/ruas/update";
export const ruasDeleteAPI = baseUrl + "/api/v1/ruas/delete/";

export const zonaAPI = baseUrl + "/api/v1/zona/search";
export const zonaDetailAPI = baseUrl + "/api/v1/zona/get/";
export const zonaNewAPI = baseUrl + "/api/v1/zona/new";
export const zonaUpdateAPI = baseUrl + "/api/v1/zona/update";
export const zonaDeleteAPI = baseUrl + "/api/v1/zona/delete/";

export const bssAPI = baseUrl + "/api/v1/bss/search";
export const bssDetailAPI = baseUrl + "/api/v1/bss/get/";
export const bssUpdateAPI = baseUrl + "/api/v1/bss/update/";
export const bssNewAPI = baseUrl + "/api/v1/bss/new";
export const bssStatAPI = baseUrl + "/api/v1/trxprojection/bssall";
export const bssUserStatAPI = baseUrl + "/api/v1/userstat/search"


// export const bssNewAPI = baseUrl + "/api/v1/bss/new";

export const reportAPI = baseUrl + "/api/v1/report/search";
export const reportDetailAPI = baseUrl + "/api/v1/report/get/";
export const reportNewAPI = baseUrl + "/api/v1/report/new";

export const trackAPI = baseUrl + "/api/v1/track/search"
export const trackDetailAPI = baseUrl + "/api/v1/track/get/"

export const trackviolationAPI = baseUrl + "/api/tra/track/search"
export const trackviolationDetailAPI = baseUrl + "/api/tra/track/get/"

export const edcAPI = baseUrl + "/api/v1/edc/search"
export const edcDetailAPI = baseUrl + "/api/v1/edc/get/"
export const edcUpdateAPI = baseUrl + "/api/v1/edc/update";
export const edcNewAPI = baseUrl + "/api/v1/edc/new";
export const edcDeleteAPI = baseUrl + "/api/v1/edc/delete/";

// using production's url
export const imgQuery = "https://app.parkir.co/api/str/file/download/image?path="

export const trxProjectionAPI = baseUrl + "/api/v1/trxprojection/all"
export const trxProjectionBSSAPI = baseUrl + "/api/v1/trxprojection/bss"
export const trxProjectionZonaAPI = baseUrl + "/api/v1/trxprojection/zona"
export const trxProjectionRuasAPI = baseUrl + "/api/v1/trxprojection/ruas"
export const trxProjectionSpaceAPI = baseUrl + "/api/v1/trxprojection/space"
export const trxProjectionJukirAPI = baseUrl + "/api/v1/trxprojection/jukir"
export const trxProjectionTidAPI = baseUrl + "/api/v1/trxprojection/tid"
export const trxProjectionPaymentAPI = baseUrl + "/api/v1/trxprojection/payment"
export const trxProjectionBookingAPI = baseUrl + "/api/v1/trxprojection/booking"
export const trxProjectionDurationAPI = baseUrl + "/api/v1/trxprojection/duration"
export const settlementAPI = baseUrl + "/api/v1/settprojection"
export const trxProjectionDetailsAPI = baseUrl + "/api/v1/trxprojection/detail"
export const trxGetByJukirAPI = baseUrl + "/api/v1/paymentproxy/jukir"


// subscription

export const subscriptionAPI = baseUrl + "/api/v1/subscription/subscription";
export const priceAPI = baseUrl + "/api/v1/subscription/price";
export const purchaseAPI = baseUrl + "/api/v1/subscription/purchase";

export const subscriptionDetailAPI = baseUrl + "/api/v1/subscription/subscription/";
export const priceDetailAPI = baseUrl + "/api/v1/subscription/price/";
export const purchaseDetailAPI = baseUrl + "/api/v1/subscription/purchase/";
export const stickerDetailAPI = baseUrl + "/api/v1/subscription/sticker/";


export const priceNewAPI = baseUrl + "/api/v1/subscription/price";
export const priceUpdateAPI = baseUrl + "/api/v1/subscription/price/update";

export const stickerAPI = baseUrl + "/api/v1/subscription/sticker";
export const stickerNewAPI = baseUrl + "/api/v1/subscription/sticker";


export const maptypeAPI = baseUrl + "/api/v1/map/search";
export const maptypeDetailAPI = baseUrl + "/api/v1/map/get/";
export const maptypeNewAPI = baseUrl + "/api/v1/map/new";
export const maptypeUpdateAPI = baseUrl + "/api/v1/map/update";
export const maptypeDeleteAPI = baseUrl + "/api/v1/map/delete/";


export const bssmaptypeAPI = baseUrl + "/api/v1/bssmap/search";
export const bssmaptypeNewAPI = baseUrl + "/api/v1/bssmap/new";


export const edcbatch   =  baseUrl + "/api/v1/edc/batch"

export const productAPI = baseUrl + "/api/v1/product/search";
export const billerAPI = baseUrl + "/api/v1/bill/search";


export const productNewAPI = baseUrl + "/api/v1/product/create";
export const billNewAPI = baseUrl + "/api/v1/bill/create";