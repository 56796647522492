import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { map,catchError,filter, first} from 'rxjs/operators'
import { Observable, of } from 'rxjs';
import * as apis from "../../utils/http/apis/list.apis"

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  headers:HttpHeaders;
  public config: PerfectScrollbarConfigInterface = {};
  public showSearch = false;
  username = '';
  email = '';

  constructor(private modalService: NgbModal, public router:Router, private http:HttpClient) {
    let usertoken= localStorage.getItem('usertoken');    
    this.headers=new HttpHeaders(
        {
            'Content-Type':  'application/json',                
            'Authorization': 'bearer '+usertoken,
        }
    ); 
  }

  ngOnInit(){
    this.username = localStorage.getItem('username');
    this.email = localStorage.getItem('email');
  }


  // This is for Notifications
  notifications: Object[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      btn: 'btn-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      btn: 'btn-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  logout(){
    this.newService(apis.logoutApi)
    .subscribe(response=>{
    of(response)
    .subscribe( (response:any) =>{ 
            alert("SUCCESS response "+ response.status)
            localStorage.clear()
            this.router.navigate(['/','authentication','login'])

            }
        )
    })

  }


  newService(url:string){
    return this.http.post(url, JSON.stringify({}), {headers: this.headers})      
    .pipe(
            map((response: Response) => {
              return response;
            }),
            catchError((error:any)=> 
            Observable.throw(error.json()||'Server error'))
            );
    }

  ngAfterViewInit() {}
}
